import React from 'react';
import { Trans, translate } from 'react-i18next';
import { Col, Container, Button } from 'reactstrap';
import { auth } from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { isEmpty } from 'react-redux-firebase';
import Auth from '../../layouts/Auth/Auth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import logo from '../../assets/img/ps-logo-white.png';

const LoginPage = translate('translations-fr')(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showFirebaseAuth: false,
        image: ''
      };
    }

    uiConfig = {
      signInFlow: 'popup',
      //credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [auth.EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: async authResult => {
          localStorage.setItem('token', authResult.user.ra);
        },
        uiShown: () => this.setState(ps => ({ ...ps, showFirebaseAuth: true }))
      }
    };

    componentWillMount() {}

    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    /**
     * @inheritDoc
     */
    componentDidMount() {
      let token = localStorage.getItem('token');
      if (!isEmpty(this.props.auth) && token !== '' && token !== 'null') {
        let { history } = this.props;
        history.push('/dashboard');
      } else {
        let random = this.randomIntFromInterval(1, 4);
        import(`../../assets/back/kitchen_${random}.jpg`).then(image => {
          this.setState({
            image: image.default
          });
        });
      }
    }

    setUserToken() {
      return new Promise((resolve, reject) => {
        auth()
          .currentUser.getIdToken(true)
          .then(token => {
            localStorage.setItem('token', token);
            resolve(true);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    render() {
      return (
        <Auth>
          <div className="full-page-content">
            <div className="login-page">
              <Container>
                <Col xs={12} className={'text-center mb-4'}>
                  <img style={{ width: '250px' }} src={logo} alt="now-logo" />
                </Col>
                <Col
                  xs={12}
                  md={8}
                  lg={4}
                  className="ml-auto mr-auto d-flex justify-content-center align-items-center"
                  style={{ minHeight: '260px' }}
                >
                  {isEmpty(this.props.auth) ? (
                    [
                      <div key="StyledFirebaseAuth">
                        <StyledFirebaseAuth
                          uiConfig={this.uiConfig}
                          firebaseAuth={auth()}
                        />
                      </div>,
                      !this.state.showFirebaseAuth ? (
                        <h4
                          key="showFirebaseAuth"
                          className="text-white text-center m-0"
                        >
                          <i className="fas fa-2x fa-cog fa-spin" />
                        </h4>
                      ) : null
                    ]
                  ) : (
                    <div className="text-center">
                      <h3 className="text-white">
                        <Trans>Hello</Trans>
                      </h3>
                      <h4 className="text-white">
                        <Trans>You are now signed In!</Trans>
                      </h4>
                      <h4 className="text-white">
                        <Trans>Redirecting</Trans>{' '}
                        <i className="fas fa-cog fa-spin" />
                      </h4>
                      <Button color={'link'} onClick={() => auth().signOut()}>
                        <Trans>Sign-out</Trans>
                      </Button>
                    </div>
                  )}
                </Col>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: 'url(' + this.state.image + ')' }}
          />
        </Auth>
      );
    }
  }
);

const mapStateToProps = state => {
  return { auth: state.fb.auth };
};

export default compose(connect(mapStateToProps))(LoginPage);
