import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../components/Spinner/Spinner';

const Dashboard = Loadable({
  loader: () => import('../views/Dashboard/Dashboard'),
  loading: () => <Spinner />
});

const Finances = Loadable({
  loader: () => import('../views/Stats/Finances'),
  loading: () => <Spinner />
});

const dashRoutes = [
  {
    path: '#dashboard',
    name: 'Dashboard',
    mini: 'D',
    state: 'dashboard',
    collapse: true,
    icon: 'design_app',
    views: [
      {
        path: '/dashboard',
        name: 'Suivi',
        mini: <i className={'fa fa-cubes'} />,
        icon: 'design_app',
        component: Dashboard
      },
      {
        path: '/finances',
        name: 'Finances',
        mini: <i className={'fa fa-money'} />,
        icon: 'design_app',
        component: Finances
      }
    ]
  },
  {
    path: '/updates',
    name: 'Notifications',
    icon: 'ui-1_bell-53',
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'updates' */ '../views/Updates/Updates'),
      loading: () => <Spinner />
    })
  },
  {
    path: '#users',
    name: 'Users',
    mini: 'U',
    state: 'users',
    collapse: true,
    icon: 'users_circle-08',
    views: [
      {
        path: '/users/all',
        name: 'Users',
        mini: 'U',
        icon: 'users_circle-08',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'clients' */ '../views/Users/All/Users'
            ),
          loading: () => <Spinner />
        })
      },
      {
        path: '/documents',
        name: 'Documents',
        mini: 'D',
        icon: 'files_single-copy-04',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'providers' */ '../views/Users/Documents/Documents.jsx'
            ),
          loading: () => <Spinner />
        })
      }
    ]
  },
  {
    path: '/projects',
    name: 'Projects',
    mini: 'PR',
    icon: 'design-2_ruler-pencil',
    state: 'openProjects',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects' */ '../layouts/Projects/Projects'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '#configuration',
    name: 'Configuration',
    mini: 'C',
    state: 'configuration',
    collapse: true,
    icon: 'loader_gear',
    views: [
      {
        path: '/operations',
        name: 'Operations',
        mini: 'OP',
        icon: 'design_bullet-list-67',
        state: 'openProjects',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'operations' */ '../views/Operations/Operations'
            ),
          loading: () => <Spinner />
        })
      },

      {
        path: '/pieces',
        name: 'Pieces',
        mini: 'PI',
        icon: 'design_bullet-list-67',
        state: 'openProjects',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'operations' */ '../views/Pieces/PiecesList'
            ),
          loading: () => <Spinner />
        })
      },
      {
        path: '/surfaces',
        name: 'Surfaces',
        mini: 'SU',
        icon: 'design_bullet-list-67',
        state: 'openProjects',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'operations' */ '../views/Surfaces/SurfacesList'
            ),
          loading: () => <Spinner />
        })
      },
      {
        path: '/packages',
        name: 'Packages',
        mini: 'PA',
        icon: 'design_bullet-list-67',
        state: 'openProjects',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'operations' */ '../views/Packages/PackagesList'
            ),
          loading: () => <Spinner />
        })
      },
      {
        path: '/images',
        name: 'Images',
        mini: 'IM',
        icon: 'design_bullet-list-67',
        state: 'openImages',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'operations' */ '../views/Images/ImagesList'
            ),
          loading: () => <Spinner />
        })
      }
    ]
  },
  {
    path: '/calendar',
    name: 'Calendar',
    icon: 'ui-1_calendar-60',
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'calendar' */ '../views/Calendar/Calendar'),
      loading: () => <Spinner />
    })
  },
  {
    path: '#catalogs',
    name: 'Catalogs',
    mini: 'C',
    state: 'catalogs',
    collapse: true,
    icon: 'shopping_basket',
    views: [
      {
        path: '/catalogs',
        name: 'Leroy Merlin',
        mini: 'LM',
        component: Loadable({
          loader: () =>
            import(/* webpackChunkName: 'operations' */ '../layouts/Catalogs'),
          loading: () => <Spinner />
        })
      },

      {
        path: '/materials',
        name: 'Placeshaker',
        mini: 'PS',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'operations' */ '../views/Material/Placeshaker/Materials'
            ),
          loading: () => <Spinner />
        })
      }
    ]
  },
  {
    path: '/providers',
    name: 'Providers',
    icon: 'business_bank',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'providers' */ '../views/Providers/Providers'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/partners',
    name: 'Partners',
    icon: 'users_single-02',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'providers' */ '../views/Partners/Partners'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '#maps',
    name: 'Maps',
    mini: 'M',
    state: 'maps',
    collapse: true,
    icon: 'location_pin',
    views: [
      {
        path: '/location/project',
        name: 'Project Locations',
        mini: 'PL',
        icon: 'location_pin',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'projects-home' */ '../views/Location/Projects'
            ),
          loading: () => <Spinner />
        })
      },
      {
        path: '/location/users',
        name: 'Users Locations',
        mini: 'UL',
        icon: 'location_pin',
        component: Loadable({
          loader: () =>
            import(
              /* webpackChunkName: 'projects-home' */ '../views/Location/Users'
            ),
          loading: () => <Spinner />
        })
      }
    ]
  },
  {
    path: '*',
    component: Dashboard
  }
];
export default dashRoutes;
